import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import {
  getLocalStorageData,
  isEmptyChecker,
  validateDepartDate,
} from "../../../../../utils/helper";
import { AirportData, BookFlightProps } from "../../../models/models";
import TGIcon from "../../../../../shared/tg-icon";
import TGButton from "../../../../../shared/tg-button";
import TGIconLabel from "../../../../../shared/tg-icon-label";
import { useDispatch, useSelector } from "react-redux";
import {
  resetBookFlightLabel,
  updateFieldData,
} from "../../../slice/bookingWidgetSlice";
import { RootState } from "../../../slice/RootReducer";
import {
  BookFlightTab,
  BookFlightTabSections,
  CabinClass,
} from "../../../../../utils/enum-helper";
import TGDateCalendar from "../../../../../shared/tg-new-datepicker";
import PassengerClass from "../../../component/passenger-class";
import moment from "moment";
import styles from "./book-flight.module.scss";
import { config } from "../../../../../config/global";
import {
  getPromoCodeFail,
  getPromoCodeSuccess,
} from "../../../slice/promoCode-Slice";
import MultiCityAutosuggest from "../multi-city/multi-city-autosuggest";

const BookFlight: React.FC<BookFlightProps> = (props: BookFlightProps) => {
  //This is to verify the screen resolution is desktop or not
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  //This is used to style based on mobile or desktop view
  const dateAlignment = !isDesktopView ? styles.dateMarginAlignment : "ps-0";
  //This is used to allow to use the translation label within the component.
  const { t, i18n } = useTranslation();
  //This is used to call the reducer function in the redux store.
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>(" ");
  const [error, setError] = useState<string>("");
  const [isError, isSetError] = useState<boolean>(false);

  const PROMO_URL = config.PROMOCODE_API;
  //UseState variables
  //This is to store button the value of Button Disable based on data selection
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  //This is used to store the promo code component state based on user selection.
  const [promoCode, setPromoCode] = useState<boolean>(false);
  //This is used to update the know the status of date Data in the book flight Tab.
  const [date, setDate] = useState<any>({
    stDate: null,
    edDate: null,
  });
  const allOptionsData: any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const promoSelectorState = useSelector(
    (state: RootState) => state?.osciReducer?.promoCode
  );
  const promoCallLoading = useSelector(
    (state: RootState) => state?.osciReducer?.promoCode.isLoading
  );

  const recentFromData = getLocalStorageData("recentFromAirport");
  const recentToData = getLocalStorageData("recentToAirport");

  //This is the state variable passed for passenger count to the passenger component.
  const [passengerCount, setPassengerCount] = useState<any>({
    adult: 1,
    youth: 0,
    child: 0,
    infant: 0,
  });

  //This is the state variable passed to track the class data value in the passenger class component.
  const [classValue, setClassValue] = useState<any>(
    t("label_economy_and_economy_plus")
  );

  const [rows, setRows] = useState<any>([
    {
      from: {},
      to: {},
    },
  ]);

  const [recentAirports, setRecentAirports] = useState({
    fromAirport: [],
    toAirport: [],
  });

  const promoCodeRef = useRef<any>(null);
  const { focused, setFocused } = props;
  const [widgetAirportList, setWidgetAirportList] = useState<any>({
    fromAirport: [],
    toAirport: [],
  });

  //UseSelector
  //This is used to verify the selected BookingField Data in the Book Flight Tab from redux store.
  const bookingFieldData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.fieldData
  );

  const bookingWidgetDataMain = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget
  );
  useEffect(() => {
    if (
      bookingWidgetDataMain.fieldData?.passengers &&
      Object.keys(bookingWidgetDataMain?.fieldData?.passengers).length !== 0
    ) {
      setPassengerCount(bookingWidgetDataMain?.fieldData?.passengers);
      setClassValue(bookingWidgetDataMain?.fieldData?.class);
    }
  }, [
    bookingWidgetDataMain?.fieldData?.passengers,
    props.activeCurrentIndex,
    bookingWidgetDataMain?.fieldData?.class,
    props.type,
  ]);

  //This is used to pass the passenger data to the parent component.
  useEffect(() => {
    if (promoSelectorState.promoStatus.status) {
      if (props?.onButtonClick) {
        props.onButtonClick();
      }
    } else {
      if (promoSelectorState.promoStatus.message == "Code is invalid") {
        isSetError(true);
        setError("Code invalid or doesn’t exist.");
      } else if (promoSelectorState.promoStatus.message == "Code expired") {
        isSetError(true);
        setError("Promo code expired.");
      } else {
        isSetError(false);
        setError("");
      }
    }
  }, [promoSelectorState.promoStatus.status, promoSelectorState]);
  const getCountData = (counts: any) => {
    if (props.onPassengerCountChange) {
      props?.onPassengerCountChange(counts);
    }
  };

  //This is used to get the radio button data from the Passenger Class component, passed to the parent component
  const getRadioData = (data: any) => {
    if (props.onClassChange) {
      props?.onClassChange(data);
    }
  };
  //This is used to handle the state of promo code variable based on user click
  const handlePromoCode = () => {
    setPromoCode((prevState: any) => !prevState);
    setCode("");
    isSetError(false);
    setError("");
  };
  const PromoCodeCallback = (data: string) => {
    const value = promoCodeRef.current.value;
    if (value.length == 0) {
      isSetError(false);
      setError("");
    }

    if (props?.onPromoCodeInput) {
      props.onPromoCodeInput(value);
    }
  };
  const handleChange = (e: any) => {
    const value = promoCodeRef.current.value;
    setCode(promoCodeRef.current.value);
    PromoCodeCallback(value);
  };

  const validationPromo = () => {
    if (props?.onPromoCall) {
      props.onPromoCall();
    }
  };
  const resetPassengerData = () => {
    dispatch(resetBookFlightLabel({ fieldLabel: "passengers" }));
    dispatch(resetBookFlightLabel({ fieldLabel: "class" }));
  };

  useEffect(() => {
    if (!props.isWidget && allOptionsData?.length > 0) {
      const airportListBySelectedCountry = allOptionsData?.filter(
        (x: AirportData) =>
          x?.countryCode?.toLowerCase() ===
          i18n.language?.split("-")?.[1]?.toLowerCase()
      );
      const defaultAirport = airportListBySelectedCountry?.filter(
        (z: AirportData) => z.isDefault
      );
      const arr = [...rows];
      arr[0].from = defaultAirport?.[0];
      setRows(arr);
    }
  }, [allOptionsData]);

  useEffect(() => {
    let fromData = [];
    let toData = [];
    if (recentFromData && Object.keys(recentFromData)?.length > 0) {
      fromData = JSON.parse(recentFromData);
    }
    if (recentToData && Object.keys(recentToData)?.length > 0) {
      toData = JSON.parse(recentToData);
    }
    setRecentAirports({
      fromAirport: fromData,
      toAirport: toData,
    });
  }, [recentFromData, recentToData]);

  //Desktop selection of recent search card for from and to airport dropdown.
  useEffect(() => {
    let fromAirportData: any;
    let toAirportData: any;
    if (isDesktopView && bookingWidgetDataMain.selectedRecentSearchData) {
      fromAirportData = allOptionsData?.filter(
        (x: any) =>
          x.airportCode ===
          bookingWidgetDataMain.selectedRecentSearchData?.from?.airportCode
      );
      toAirportData = allOptionsData?.filter(
        (x: any) =>
          x.airportCode ===
          bookingWidgetDataMain.selectedRecentSearchData?.to?.airportCode
      );
      if (fromAirportData && toAirportData) {
        let temp = {
          ...rows[0],
          from: fromAirportData[0],
          to: toAirportData[0],
        };
        const tempArr = [...rows];
        tempArr[0] = temp;
        setRows([...tempArr]);
        props?.getFlightData(tempArr);
      }
    } else if (
      !isDesktopView &&
      bookingWidgetDataMain.selectedRecentSearchDataMobile
    ) {
      fromAirportData = allOptionsData?.filter(
        (x: any) =>
          x.airportCode ===
          bookingWidgetDataMain.selectedRecentSearchDataMobile?.from
            ?.airportCode
      );
      toAirportData = allOptionsData?.filter(
        (x: any) =>
          x.airportCode ===
          bookingWidgetDataMain.selectedRecentSearchDataMobile?.to?.airportCode
      );
      if (fromAirportData && toAirportData) {
        let temp = {
          ...rows[0],
          from: fromAirportData[0],
          to: toAirportData[0],
        };
        const tempArr = [...rows];
        tempArr[0] = temp;
        setRows([...tempArr]);
        props?.getFlightData(tempArr);
      }
    }
  }, [
    bookingWidgetDataMain.selectedRecentSearchData,
    allOptionsData,
    isDesktopView,
    bookingWidgetDataMain.selectedRecentSearchDataMobile,
  ]);

  //Passenger, class and date data pre-filling in desktop view
  useEffect(() => {
    let passengerData: any;
    let cabinClassData: any;
    if (isDesktopView && bookingWidgetDataMain.selectedRecentSearchData) {
      passengerData =
        bookingWidgetDataMain.selectedRecentSearchData?.passengers;
      setPassengerCount(passengerData);
      dispatch(
        updateFieldData({ fieldLabel: "passengers", newValue: passengerData })
      );
      if (
        bookingWidgetDataMain?.selectedRecentSearchData?.class ===
        CabinClass.Economy
      ) {
        cabinClassData = t("label_economy_and_economy_plus");
      } else {
        cabinClassData = t("label_business_and_first");
      }
      setClassValue(cabinClassData);
      dispatch(
        updateFieldData({ fieldLabel: "class", newValue: cabinClassData })
      );
      //This is to validate the depart date is less than today's date.
      const isDepartDateBeforeNow = validateDepartDate(
        bookingWidgetDataMain?.selectedRecentSearchData?.depart
      );
      if (
        bookingWidgetDataMain?.selectedRecentSearchData?.depart &&
        !isDepartDateBeforeNow &&
        bookingWidgetDataMain?.selectedRecentSearchData?.type ===
          BookFlightTabSections.OneWay
      ) {
        setDate({
          stDate: moment(
            bookingWidgetDataMain?.selectedRecentSearchData?.depart,
            "YYYY-MM-DD"
          ).toDate(),
        });
        dispatch(
          updateFieldData({
            fieldLabel: "depart",
            newValue: [
              moment(
                bookingWidgetDataMain?.selectedRecentSearchData?.depart,
                "YYYY-MM-DD"
              ).toDate(),
            ],
          })
        );
      } else if (
        bookingWidgetDataMain?.selectedRecentSearchData?.depart &&
        isDepartDateBeforeNow &&
        bookingWidgetDataMain?.selectedRecentSearchData?.type ===
          BookFlightTabSections.OneWay
      ) {
        setDate({
          stDate: null,
          edDate: null,
        });
        dispatch(resetBookFlightLabel({ fieldLabel: "depart" }));
      } else if (
        bookingWidgetDataMain?.selectedRecentSearchData?.depart &&
        !isDepartDateBeforeNow &&
        bookingWidgetDataMain?.selectedRecentSearchData?.type ===
          BookFlightTabSections.RoundTrip
      ) {
        setDate({
          stDate: moment(
            bookingWidgetDataMain?.selectedRecentSearchData?.depart,
            "YYYY-MM-DD"
          ).toDate(),
          edDate: moment(
            bookingWidgetDataMain?.selectedRecentSearchData?.return,
            "YYYY-MM-DD"
          ).toDate(),
        });
        dispatch(
          updateFieldData({
            fieldLabel: "departReturn",
            newValue: [
              moment(
                bookingWidgetDataMain?.selectedRecentSearchData?.depart,
                "YYYY-MM-DD"
              ).toDate(),
              moment(
                bookingWidgetDataMain?.selectedRecentSearchData?.return,
                "YYYY-MM-DD"
              ).toDate(),
            ],
          })
        );
      } else if (
        bookingWidgetDataMain?.selectedRecentSearchData?.depart &&
        isDepartDateBeforeNow &&
        bookingWidgetDataMain?.selectedRecentSearchData?.type ===
          BookFlightTabSections.RoundTrip
      ) {
        setDate({
          stDate: null,
          edDate: moment(
            bookingWidgetDataMain?.selectedRecentSearchData?.return,
            "YYYY-MM-DD"
          ).toDate(),
        });
        dispatch(resetBookFlightLabel({ fieldLabel: "departReturn" }));
      }
    } else {
      setDate({
        stDate: null,
        edDate: null,
      });
    }
  }, [
    bookingWidgetDataMain?.selectedRecentSearchData,
    t,
    dispatch,
    isDesktopView,
  ]);

  //Passenger, class and date data pre-filling in mobile view
  useEffect(() => {
    let passengerData: any;
    let cabinClassData: any;
    if (
      !isDesktopView &&
      bookingWidgetDataMain.selectedRecentSearchDataMobile
    ) {
      passengerData =
        bookingWidgetDataMain.selectedRecentSearchDataMobile.passengers;
      setPassengerCount(passengerData);
      dispatch(
        updateFieldData({ fieldLabel: "passengers", newValue: passengerData })
      );
      if (
        bookingWidgetDataMain.selectedRecentSearchDataMobile.class ===
        CabinClass.Economy
      ) {
        cabinClassData = t("label_economy_and_economy_plus");
      } else {
        cabinClassData = t("label_business_and_first");
      }
      setClassValue(cabinClassData);
      dispatch(
        updateFieldData({ fieldLabel: "class", newValue: cabinClassData })
      );
      const isDepartDateBeforeNow = validateDepartDate(
        bookingWidgetDataMain?.selectedRecentSearchDataMobile?.depart
      );
      if (
        bookingWidgetDataMain.selectedRecentSearchDataMobile.depart &&
        !isDepartDateBeforeNow &&
        bookingWidgetDataMain.selectedRecentSearchDataMobile.type ===
          BookFlightTabSections.OneWay
      ) {
        setDate({
          stDate: moment(
            bookingWidgetDataMain.selectedRecentSearchDataMobile.depart,
            "YYYY-MM-DD"
          ).toDate(),
        });
        dispatch(
          updateFieldData({
            fieldLabel: "depart",
            newValue: [
              moment(
                bookingWidgetDataMain.selectedRecentSearchDataMobile.depart,
                "YYYY-MM-DD"
              ).toDate(),
            ],
          })
        );
      } else if (
        bookingWidgetDataMain.selectedRecentSearchDataMobile.depart &&
        !isDepartDateBeforeNow &&
        bookingWidgetDataMain.selectedRecentSearchDataMobile.type ===
          BookFlightTabSections.RoundTrip
      ) {
        setDate({
          stDate: moment(
            bookingWidgetDataMain.selectedRecentSearchDataMobile.depart,
            "YYYY-MM-DD"
          ).toDate(),
          edDate: moment(
            bookingWidgetDataMain.selectedRecentSearchDataMobile.return,
            "YYYY-MM-DD"
          ).toDate(),
        });
        dispatch(
          updateFieldData({
            fieldLabel: "departReturn",
            newValue: [
              moment(
                bookingWidgetDataMain?.selectedRecentSearchDataMobile?.depart,
                "YYYY-MM-DD"
              ).toDate(),
              moment(
                bookingWidgetDataMain?.selectedRecentSearchDataMobile?.return,
                "YYYY-MM-DD"
              ).toDate(),
            ],
          })
        );
      } else if (
        bookingWidgetDataMain.selectedRecentSearchDataMobile.depart &&
        isDepartDateBeforeNow &&
        bookingWidgetDataMain.selectedRecentSearchDataMobile.type ===
          BookFlightTabSections.RoundTrip
      ) {
        setDate({
          stDate: null,
          edDate: moment(
            bookingWidgetDataMain.selectedRecentSearchDataMobile.return,
            "YYYY-MM-DD"
          ).toDate(),
        });
        dispatch(resetBookFlightLabel({ fieldLabel: "departReturn" }));
      } else {
        setDate({
          stDate: null,
          edDate: null,
        });
        dispatch(resetBookFlightLabel({ fieldLabel: "departReturn" }));
        dispatch(resetBookFlightLabel({ fieldLabel: "depart" }));
      }
    }
  }, [
    bookingWidgetDataMain.selectedRecentSearchDataMobile,
    t,
    dispatch,
    isDesktopView,
  ]);

  //This useEffect verifies the status of Search Flight button in the case of Round Trip and One Way Tabs in Booking Widget for mobile and desktop view.
  useEffect(() => {
    if (props.type === BookFlightTab.Return) {
      if (
        !isEmptyChecker(rows[0]?.from) &&
        !isEmptyChecker(rows[0]?.to) &&
        bookingFieldData?.departReturn[0] &&
        bookingFieldData?.departReturn[1] &&
        Object.keys(bookingFieldData?.passengers).length !== 0
      ) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    } else if (props.type === BookFlightTab.OneWay) {
      if (
        !isEmptyChecker(rows[0]?.from) &&
        !isEmptyChecker(rows[0]?.to) &&
        bookingFieldData?.depart &&
        Object.keys(bookingFieldData?.passengers).length !== 0
      ) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    }
  }, [bookingFieldData, props.type, rows]);

  //This useEffect is to update the date field when user changes the tab in the case of Desktop and Mobile view.
  //The condition used is in the case of tab switch alone and not when the selected card data switch happens.
  useEffect(() => {
    if (
      (isDesktopView && !bookingWidgetDataMain?.selectedRecentSearchData) ||
      (!isDesktopView && !bookingWidgetDataMain.selectedRecentSearchDataMobile)
    )
      if (props.type === BookFlightTab.Return) {
        setDate((prevState: any) => {
          return {
            ...prevState,
            stDate: bookingWidgetDataMain?.fieldData?.departReturn[0],
            edDate: bookingWidgetDataMain?.fieldData?.departReturn[1],
          };
        });
      } else {
        setDate((prevState: any) => {
          return {
            ...prevState,
            stDate: bookingWidgetDataMain?.fieldData?.depart,
            edDate: null,
          };
        });
      }
  }, [
    props.type,
    setDate,
    props.tabIndex,
    bookingWidgetDataMain?.selectedRecentSearchData,
    bookingWidgetDataMain.selectedRecentSearchDataMobile,
    isDesktopView,
    bookingWidgetDataMain.fieldData,
  ]);

  useEffect(() => {
    let fromAirport;
    let toAirport;
    let selectedFromAirport;
    let selectedToAirport;
    let cabinClassData;
    if (props.isWidget && props?.options.length > 0) {
      if (!props?.widgetParams?.params?.defaultAirportList) {
        switch (props.widgetParams.params.airportFilterType) {
          case "Country":
            fromAirport = props?.options.filter(
              (x: any) =>
                x?.countryName?.toLowerCase() ===
                props?.widgetParams?.params.airportFilterFrom.toLowerCase()
            );
            toAirport = props?.options.filter(
              (x: any) =>
                x?.countryName?.toLowerCase() ===
                props?.widgetParams?.params?.airportFilterTo.toLowerCase()
            );
            setWidgetAirportList({
              fromAirport: fromAirport,
              toAirport: toAirport,
            });
            if (props.widgetParams?.params?.airportFilter) {
              selectedFromAirport = props?.options.filter(
                (x: any) =>
                  x?.airportCode?.toLowerCase() ===
                  props?.widgetParams?.params?.originAirport.toLowerCase()
              );
              selectedToAirport = props?.options.filter(
                (x: any) =>
                  x?.airportCode?.toLowerCase() ===
                  props?.widgetParams?.params?.departureAirport.toLowerCase()
              );
              let temp = {
                ...rows[0],
                from: selectedFromAirport[0],
                to: selectedToAirport[0],
              };
              const tempArr = [...rows];
              tempArr[0] = temp;
              setRows([...tempArr]);
              props?.getFlightData(tempArr);
            }

            break;
          case "City":
            fromAirport = props?.options.filter(
              (x: any) =>
                x?.cityName?.toLowerCase() ===
                props?.widgetParams?.params?.airportFilterFrom.toLowerCase()
            );
            toAirport = props?.options.filter(
              (x: any) =>
                x?.cityName?.toLowerCase() ===
                props?.widgetParams?.params?.airportFilterTo.toLowerCase()
            );
            setWidgetAirportList({
              fromAirport: fromAirport,
              toAirport: toAirport,
            });
            break;
          default:
            break;
        }
      } else {
        setWidgetAirportList({
          fromAirport: props?.options,
          toAirport: props?.options,
        });
        selectedFromAirport = props?.options.filter(
          (x: any) =>
            x?.airportCode?.toLowerCase() ===
            props?.widgetParams?.params?.originAirport.toLowerCase()
        );
        selectedToAirport = props?.options.filter(
          (x: any) =>
            x?.airportCode?.toLowerCase() ===
            props?.widgetParams?.params?.departureAirport.toLowerCase()
        );
        let temp = {
          ...rows[0],
          from: selectedFromAirport[0],
          to: selectedToAirport[0],
        };
        const tempArr = [...rows];
        tempArr[0] = temp;
        setRows([...tempArr]);
        props?.getFlightData(tempArr);
      }
      if (props?.widgetParams?.params?.tripType === "Return") {
        if (
          !isEmptyChecker(props?.widgetParams?.params?.startDate) ||
          !isEmptyChecker(props?.widgetParams?.params?.endDate)
        ) {
          setDate({
            stDate: moment(
              props?.widgetParams?.params?.startDate,
              "YYYY-MM-DD"
            ).toDate(),
            edDate: moment(
              props?.widgetParams?.params?.endDate,
              "YYYY-MM-DD"
            ).toDate(),
          });

          dispatch(
            updateFieldData({
              fieldLabel: "departReturn",
              newValue: [
                moment(
                  props?.widgetParams?.params?.startDate,
                  "YYYY-MM-DD"
                ).toDate(),
                moment(
                  props?.widgetParams?.params?.endDate,
                  "YYYY-MM-DD"
                ).toDate(),
              ],
            })
          );
        } else {
          setDate({
            stDate: null,
            edDate: null,
          });

          dispatch(
            updateFieldData({
              fieldLabel: "departReturn",
              newValue: [null, null],
            })
          );
        }
      } else {
        if (!isEmptyChecker(props?.widgetParams?.params?.startDate)) {
          setDate({
            stDate: moment(
              props?.widgetParams?.params?.startDate,
              "YYYY-MM-DD"
            ).toDate(),
          });
          dispatch(
            updateFieldData({
              fieldLabel: "depart",
              newValue: [
                moment(
                  props?.widgetParams?.params?.startDate,
                  "YYYY-MM-DD"
                ).toDate(),
              ],
            })
          );
        } else {
          setDate({
            stDate: null,
            edDate: null,
          });
          dispatch(
            updateFieldData({
              fieldLabel: "depart",
              newValue: [null, null],
            })
          );
        }
      }
      if (!isEmptyChecker(props?.widgetParams?.params?.class)) {
        if (props?.widgetParams?.params?.class === CabinClass.Economy) {
          cabinClassData = t("label_economy_and_economy_plus");
        } else {
          cabinClassData = t("label_business_and_first");
        }
        setClassValue(cabinClassData);
        dispatch(
          updateFieldData({
            fieldLabel: "class",
            newValue: cabinClassData,
          })
        );
      } else {
        cabinClassData = t("label_economy_and_economy_plus");
        setClassValue(cabinClassData);
        dispatch(
          updateFieldData({
            fieldLabel: "class",
            newValue: cabinClassData,
          })
        );
      }
      if (!isEmptyChecker(props?.widgetParams?.params?.passengers)) {
        if (props?.widgetParams?.params?.passengers?.isRequired === "true") {
          let passengerData = {
            adult: props?.widgetParams?.params?.passengers?.adult?.value,
            youth: props?.widgetParams?.params?.passengers?.youth?.value,
            child: props?.widgetParams?.params?.passengers?.child?.value,
            infant: props?.widgetParams?.params?.passengers?.infant?.value,
          };
          setPassengerCount(passengerData);
          dispatch(
            updateFieldData({
              fieldLabel: "passengers",
              newValue: passengerData,
            })
          );
        } else {
          let passengerData = {
            adult: 1,
            youth: 0,
            child: 0,
            infant: 0,
          };
          dispatch(
            updateFieldData({
              fieldLabel: "passengers",
              newValue: passengerData,
            })
          );
        }
      } else {
        let passengerData = {
          adult: 1,
          youth: 0,
          child: 0,
          infant: 0,
        };
        dispatch(
          updateFieldData({
            fieldLabel: "passengers",
            newValue: passengerData,
          })
        );
      }
    }
  }, [
    allOptionsData,
    props.isWidget,
    props?.widgetParams?.params?.passengers?.isRequired,
  ]);

  //This useEffect ensures that the Passenger Count and class state becomes reset when the selected AirportData is null

  const handleResetCalender = (label: string) => {
    dispatch(
      resetBookFlightLabel({
        fieldLabel: label
          .replace(/[^\w\s-]/g, "")
          .split(/[\s-]+/)
          .map((word: any, index: any) =>
            index === 0
              ? word.toLowerCase()
              : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(""),
      })
    );
  };

  const handleSelect = (
    value: AirportData,
    fieldName: string,
    currentIndex: number
  ) => {
    let temp = {
      ...rows[currentIndex],
      [fieldName]: value,
    };
    if (fieldName === "from") {
      if (value?.airportCode === rows[currentIndex]?.to?.airportCode) {
        temp = { ...temp, to: {} };
      }
      setFocused({ from: false, to: true, date: false, pax: false });
    } else if (fieldName === "to") {
      if (value?.airportCode === rows[currentIndex]?.from?.airportCode) {
        temp = { ...temp, from: {} };
      }
      setFocused({ from: false, to: false, date: true, pax: false });
    }
    const tempArr = [...rows];
    tempArr[currentIndex] = temp;
    setRows([...tempArr]);

    props?.getFlightData(tempArr);
  };

  const handleSwap = (index: number) => {
    if (
      Object.keys(rows[index]?.from)?.length > 0 &&
      Object.keys(rows[index]?.to)?.length > 0
    ) {
      const temp = {
        ...rows[index],
        from: rows[index]?.to,
        to: rows[index]?.from,
      };
      const tempArr = [...rows];
      tempArr[index] = temp;
      setRows([...tempArr]);
      props?.getFlightData(tempArr);
    }
  };

  const handleClear = (currentField: string, index: number) => {
    const temp = {
      ...rows[index],
      [currentField]: {},
    };
    const tempArr = [...rows];
    tempArr[index] = temp;
    setRows([...tempArr]);
  };

  const handleClearRecentSearch = (field: string) => {
    if (field === "from") {
      setRecentAirports({ ...recentAirports, fromAirport: [] });
    } else if (field === "to") {
      setRecentAirports({ ...recentAirports, toAirport: [] });
    }
  };

  const filterRecentAirportsDuplicate = (list: any, selectedObj: any) => {
    const filteredOptionArr = list?.filter(
      (x: any) => x?.airportCode !== selectedObj?.airportCode
    );
    return filteredOptionArr;
  };

  return (
    <>
      <Row className={`${isDesktopView ? "px-2" : ""}`}>
        {props.type === BookFlightTab.Return && (
          <>
            <Col lg={8} sm={12} xs={12} md={8} className={dateAlignment}>
              <div className={styles["multicity-destination-container"]}>
                <MultiCityAutosuggest
                  label={t("label_book_widget_from")}
                  customClassName="fromDropdownBorderRadius"
                  onChange={() => {}}
                  onSelect={handleSelect}
                  searchPlaceHolder={t("label_multi_city_search_placeholder")}
                  name="from"
                  options={
                    !props?.isWidget
                      ? allOptionsData
                      : widgetAirportList?.fromAirport
                  }
                  rowIndex={0}
                  selectedPropsValue={rows[0].from}
                  onClear={(i: number) => handleClear("from", i)}
                  reverseArrow={true}
                  onSwap={handleSwap}
                  recentAirport={filterRecentAirportsDuplicate(
                    recentAirports?.fromAirport,
                    rows[0]?.to
                  )?.reverse()}
                  onClearRecentSearch={handleClearRecentSearch}
                  isFocused={focused.from}
                  onAutoSuggestBlur={() => {
                    setFocused({
                      ...focused,
                      from: false,
                      to: false,
                      date: false,
                      pax: false,
                    });
                  }}
                />
                <MultiCityAutosuggest
                  label={t("label_book_widget_to")}
                  customClassName="toDropdownBorderRadius"
                  onChange={() => {}}
                  onSelect={handleSelect}
                  searchPlaceHolder={t("label_multi_city_search_placeholder")}
                  name="to"
                  options={
                    !props?.isWidget
                      ? allOptionsData
                      : widgetAirportList?.toAirport
                  }
                  rowIndex={0}
                  selectedPropsValue={rows[0]?.to}
                  onClear={(i: number) => handleClear("to", i)}
                  recentAirport={filterRecentAirportsDuplicate(
                    recentAirports?.toAirport,
                    rows[0]?.from
                  )?.reverse()}
                  onClearRecentSearch={handleClearRecentSearch}
                  isFocused={focused.to}
                  onAutoSuggestBlur={() => {
                    setFocused({
                      ...focused,
                      from: false,
                      to: false,
                      date: false,
                      pax: false,
                    });
                  }}
                />
              </div>
            </Col>
            <Col lg={4} sm={12} xs={12} md={4} className={dateAlignment}>
              {props.currentTabIndex === 0 && (
                <TGDateCalendar
                  label={t("label_book_widget_depart_return")}
                  onChange={(e, label) =>
                    props.onChangeInput(e, "depart Return")
                  }
                  type="2way"
                  dateData={date}
                  setDateData={setDate}
                  onReset={handleResetCalender}
                  focused={props.focused}
                  setFocused={setFocused}
                  onDateBlur={() => {}}
                />
              )}
            </Col>
          </>
        )}
        {props.type === BookFlightTab.OneWay && (
          <>
            <Col lg={8} sm={12} xs={12} md={8} className={dateAlignment}>
              <div className={styles["multicity-destination-container"]}>
                <MultiCityAutosuggest
                  label={t("label_book_widget_from")}
                  customClassName="fromDropdownBorderRadius"
                  onChange={() => {}}
                  onSelect={handleSelect}
                  searchPlaceHolder={t("label_multi_city_search_placeholder")}
                  name="from"
                  options={
                    !props?.isWidget
                      ? allOptionsData
                      : widgetAirportList?.fromAirport
                  }
                  rowIndex={0}
                  selectedPropsValue={rows[0]?.from}
                  onClear={(i: number) => handleClear("from", i)}
                  reverseArrow={true}
                  onSwap={handleSwap}
                  recentAirport={filterRecentAirportsDuplicate(
                    recentAirports?.fromAirport,
                    rows[0]?.to
                  )?.reverse()}
                  onClearRecentSearch={handleClearRecentSearch}
                  isFocused={focused.from}
                  onAutoSuggestBlur={() => {
                    setFocused({
                      ...focused,
                      from: false,
                      to: false,
                      date: false,
                      pax: false,
                    });
                  }}
                />
                <MultiCityAutosuggest
                  label={t("label_book_widget_to")}
                  customClassName="toDropdownBorderRadius"
                  onChange={() => {}}
                  onSelect={handleSelect}
                  searchPlaceHolder={t("label_multi_city_search_placeholder")}
                  name="to"
                  options={
                    !props?.isWidget
                      ? allOptionsData
                      : widgetAirportList?.toAirport
                  }
                  rowIndex={0}
                  selectedPropsValue={rows[0]?.to}
                  onClear={(i: number) => handleClear("to", i)}
                  recentAirport={filterRecentAirportsDuplicate(
                    recentAirports?.toAirport,
                    rows[0]?.from
                  )?.reverse()}
                  onClearRecentSearch={handleClearRecentSearch}
                  isFocused={focused.to}
                  onAutoSuggestBlur={() => {
                    setFocused({
                      ...focused,
                      from: false,
                      to: false,
                      date: false,
                      pax: false,
                    });
                  }}
                />
              </div>
            </Col>
            <Col lg={4} sm={12} xs={12} md={4} className={dateAlignment}>
              {props.currentTabIndex === 1 && (
                <TGDateCalendar
                  label={t("label_book_widget_depart")}
                  onChange={(e, label) => props.onChangeInput(e, "depart")}
                  type="1way"
                  dateData={date}
                  setDateData={setDate}
                  onReset={handleResetCalender}
                  focused={props.focused}
                  setFocused={setFocused}
                  onDateBlur={() => {}}
                />
              )}
            </Col>
          </>
        )}
      </Row>
      {isDesktopView && (
        <Row
          style={{
            marginTop: "20px",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            cursor: "pointer",
          }}
        >
          <Col
            lg={4}
            sm={12}
            xs={12}
            md={3}
            className={`${!isDesktopView ? "mb-2" : "ps-0 pe-0"}`}
            tabIndex={0}
          >
            <PassengerClass
              handleCountSelection={getCountData}
              handleRadioChange={getRadioData}
              handleReset={resetPassengerData}
              focused={props.focused}
              setFocused={setFocused}
              counter={passengerCount}
              setCounter={setPassengerCount}
              classData={classValue}
              setClassData={setClassValue}
              country={bookingFieldData?.from}
              isWidget={props?.isWidget}
              widgetParams={props?.widgetParams}
            />
          </Col>
          <Col
            className={`d-flex justify-content-start ps-0 ${
              !promoCode ? "" : "mb-3"
            }`}
            lg={3}
            sm={12}
            xs={12}
            md={3}
            title={
              !promoCode
                ? t("label_book_widget_code")
                : t("label_book_widget_promotion")
            }
            role="button"
            tabIndex={0}
          >
            {!promoCode && (
              <TGIconLabel
                icon="plus-icon"
                label={t("label_book_widget_code")}
                position="left"
                onClick={handlePromoCode}
                optionalClassName="iconLabelAlignment"
                iconClassName="promoIconStyle"
              />
            )}
            {promoCode && (
              <div>
                <InputGroup
                  className={
                    isError
                      ? styles.errorPromoContainer
                      : styles.mainPromoContainer
                  }
                >
                  <Form.Control
                    value={code}
                    ref={promoCodeRef}
                    aria-label="Text input with dropdown button"
                    placeholder={t("label_book_widget_tg")}
                    onChange={(e: any) => {
                      props.onChangeInput(
                        e,
                        t("label_book_widget_promotion", { lng: "en" })
                      );
                      handleChange(e);
                    }}
                  ></Form.Control>
                  {isError && (
                    <div aria-label="error-icon">
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                    </div>
                  )}
                  <button
                    onClick={handlePromoCode}
                    className={styles.buttonRadius}
                    aria-label="close-button"
                  >
                    <TGIcon icon="promo-close" fillColor="" size="" />
                  </button>
                </InputGroup>
                {isError && (
                  <Form.Label className={styles["tg-input-error-label"]}>
                    {error}
                  </Form.Label>
                )}
              </div>
            )}
          </Col>
          <Col className="d-flex justify-content-end align-items-top pe-0">
            <span className="ms-4" title={t("button_search_flights")}>
              <Button
                className={styles.buttonContainer}
                onClick={
                  code.length == 0 || code == " "
                    ? props.onButtonClick
                    : validationPromo
                }
                disabled={buttonDisable}
                aria-label="Search Flights Button"
              >
                <span>{t("button_search_flights")}</span>
              </Button>
            </span>
          </Col>
        </Row>
      )}
      {!isDesktopView && (
        <Row className="mb-4">
          <Col
            sm={12}
            xs={12}
            className={`${!isDesktopView ? "mb-2" : "ps-0 pe-0"}`}
            tabIndex={0}
          >
            <PassengerClass
              handleCountSelection={getCountData}
              handleRadioChange={getRadioData}
              handleReset={resetPassengerData}
              focused={props.focused}
              setFocused={setFocused}
              counter={passengerCount}
              setCounter={setPassengerCount}
              classData={classValue}
              setClassData={setClassValue}
              country={bookingFieldData?.from}
              isWidget={props?.isWidget}
              widgetParams={props?.widgetParams}
            />
          </Col>
          <Col xs={12} sm={12} className="mb-4">
            {!promoCode && (
              <TGIconLabel
                icon="plus-icon-mobile"
                label={t("label_book_widget_code")}
                position="left"
                onClick={handlePromoCode}
                optionalClassName="promoIconLabelContainer"
                iconClassName="promoIconStyle"
              />
            )}
            {promoCode && (
              <div>
                <InputGroup
                  className={
                    isError
                      ? styles.errorPromoContainer
                      : styles.mainPromoContainer
                  }
                >
                  <Form.Control
                    value={code}
                    ref={promoCodeRef}
                    aria-label="Text input with dropdown button"
                    placeholder={t("label_book_widget_tg")}
                    onChange={(e: any) => {
                      props.onChangeInput(
                        e,
                        t("label_book_widget_promotion", { lng: "en" })
                      );
                      handleChange(e);
                    }}
                  ></Form.Control>
                  {isError && (
                    <div aria-label="error-icon">
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                    </div>
                  )}
                  <button
                    onClick={handlePromoCode}
                    className={styles.buttonRadius}
                    aria-label="close-button"
                  >
                    <TGIcon icon="promo-close" fillColor="" size="" />
                  </button>
                </InputGroup>
                {isError && (
                  <Form.Label className={styles["tg-input-error-label"]}>
                    {error}
                  </Form.Label>
                )}
              </div>
            )}
          </Col>
          <Col xs={12} sm={12} className="px-2">
            <TGButton
              label={t("button_search_flights")}
              variant="primary"
              buttonSize="lg"
              onClick={
                code.length == 0 || code == " "
                  ? props.onButtonClick
                  : validationPromo
              }
              customClass="bookingButtonContainer"
              disabled={buttonDisable}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BookFlight;

import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TGButton from "../../../../../shared/tg-button";
import { BookingCheckProps, tripDetails } from "../../../models/models";
import { useDispatch, useSelector } from "react-redux";
import { updateFieldData } from "../../../slice/bookingWidgetSlice";
import { RootState } from "../../../slice/RootReducer";
import {
  blockSpecialChar,
  getCookie,
  handleAmadeusRedirection,
  isvalidAlphabet,
} from "../../../../../utils/helper";
import { config } from "../../../../../config/global";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import TGTextField from "../../../../../shared/tg-text-field";
import styles from "./check-in.module.scss";
import TGMultiCard from "../../../../../shared/tg-multi-card";
import isAuthenticate from "../../../../../utils/hook/isAuthenticate";
import TGIcon from "../../../../../shared/tg-icon";
import {
  getTripDataFailure,
  getTripDataSuccess,
  tripDataRequest,
} from "../../../slice/tripSlice";
import FlightStatusUpcomingTrips from "../flight-status/flight-status-upcoming-trips";
import LoginWidget from "../../../../../shared/login-widget";
import "../../../../../i18n";
import { useNavigate } from "react-router-dom";
import i18n from "../../../../../i18n";
import { retrieveJourneyRequest } from "../../../../../DAPI/src/slice/checkin/checkinSlice";
import FlightCheckinDetails from "../../../../../DAPI/src/component/check-in/checkin-flights-popup";

//  Textinput component
const TextINputField = () => {
  const { t } = useTranslation();
  const isAuthenticated = isAuthenticate();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [isTicketNoError, setIsTicketNoError] = useState(false);
  const [ticketNo, setTicketNo] = useState("");
  const [isLastNameError, setIsLastNameError] = useState(false);
  const name = isAuthenticated
    ? sessionStorage.getItem("isROPLoggedIn") === "true"
      ? sessionStorage.getItem("FullName")
      : sessionStorage.getItem("lastName")
    : "";
  const prefilledInput =
    sessionStorage.getItem("isROPLoggedIn") === "true"
      ? name?.split(" ")[name?.split(" ").length - 1].toUpperCase()
      : name; // it takes the last name from the signed-in user
  const [lastName, setLastName] = useState(
    isAuthenticated ? prefilledInput : ""
  );
  const [isBookingNoError, setIsBookingNoError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [checkinError, setCheckinError] = useState<any>(false);
  const [showFlight, setShowflight] = useState(false);
  const [flightDetailsList, setFlightDetailsList] = useState<any>();

  const bookingRegex = new RegExp(
    /^(?=.{6}$)(?![0-9]{6})(?![a-zA-Z]{6})[a-zA-Z0-9]{6}$/
  ); // regex exp for booking reference no
  const ticketRegex = new RegExp(/^[0-9]{13}$/); // regex exp for ticket no
  const ropRegex = new RegExp(/^[a-zA-Z]{2}[0-9]{5}$/); // regex exp for ROP number
  const nameRegex = new RegExp(/^(?!\s*$)[a-zA-Z\s]{2,}$/); // regex exp for last name
  const [maxLength, setMaxLength] = useState(0);
  const airportFieldData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.fieldData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //  useEffect to prepopulate the last name if user signed in
  useEffect(() => {
    if (isAuthenticated) {
      setLastName(prefilledInput);
      setIsLastNameError(true);
    }
    if (/^(0|[1-9][0-9]*)$/.test(ticketNo)) {
      setMaxLength(13);
    } else if (/^[a-zA-z0-9]{0,20}/.test(ticketNo)) {
      setMaxLength(7);
    }
  }, [ticketNo]);

  const retrieveJourneyResponse = useSelector(
    (state: RootState) => state?.dapiReducer?.checkin
  );

  // TODO: need to check dispatch method.
  const handleChangeInput = (newValue: any, label: any) => {
    //This conversion is used to convert the labels send from onChange function to lowerCamelCase format.
    const fieldLabel = label
      .replace(/[^\w\s-]/g, "")
      .split(/[\s-]+/)
      .map((word: any, index: any) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");

    dispatch(updateFieldData({ fieldLabel, newValue }));
  };

  //  Fucniton handles booking no and ticket no
  const handleBookingNum = (a: any, label: string) => {
    if (blockSpecialChar(a.target.value)) {
      setTicketNo(a.target.value.toUpperCase());
      setIsBookingNoError(false);
      if (a.target.value.length > 0 && a.target.value != null) {
        setIsTicketNoError(true);
        setTicketNo(a.target.value.toUpperCase());
        handleChangeInput(a, label);
      } else {
        setIsTicketNoError(false);
      }
    }
  };

  //  Fucntion handles last name input.
  const handlelastName = (b: any, label: string) => {
    if (isvalidAlphabet(b.target.value)) {
      setLastName(b.target.value.toUpperCase());
      setIsNameError(false);
      if (b.target.value.length > 1 && b.target.value != null) {
        setIsLastNameError(true);
        setLastName(b.target.value.toUpperCase());
        handleChangeInput(b, label);
      } else {
        setIsLastNameError(false);
      }
    }
  };

  //  fucntion handles booking criteria, check the booking reference number, ticket number, ROP number and last name
  const handleBooking = () => {
    if (
      ticketNo.length === 6 ||
      ticketNo.length === 7 ||
      ticketNo.length === 13
    ) {
      if (
        bookingRegex.test(ticketNo) ||
        ropRegex.test(ticketNo) ||
        ticketRegex.test(ticketNo)
      ) {
        if (nameRegex.test(lastName)) {
          handleCheckIn();
        } else {
          setIsNameError(true);
        }
      } else {
        setIsBookingNoError(true);
      }
    } else {
      setIsBookingNoError(true);
    }
  };

  // const retrieveJourneyResponse = {
  //   checkinDetails: [
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "eligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358969",
  //       passengers: [
  //         {
  //           firstName: "MANOJ",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRB",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "ineligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358990",
  //       passengers: [
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRX",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "eligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358990",
  //       passengers: [
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRX",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "ineligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358990",
  //       passengers: [
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRX",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "eligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358990",
  //       passengers: [
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRX",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "eligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358990",
  //       passengers: [
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRX",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },
  //     {
  //       acceptance: {
  //         notCheckedInJourneyElements: [
  //           {
  //             id: "5006540E0001D977",
  //           },
  //         ],
  //         isAccepted: false,
  //         isPartial: false,
  //         isVoluntaryDeniedBoarding: false,
  //       },
  //       acceptanceEligibility: {
  //         status: "eligible",
  //         eligibilityWindow: {
  //           openingDateAndTime: "2024-10-29T08:30:00Z",
  //           closingDateAndTime: "2024-10-30T07:30:00Z",
  //         },
  //         reasons: ["flightAcceptanceEligibilityWindow"],
  //       },
  //       journeyElements: [
  //         {
  //           id: "5006540E0001D977",
  //         },
  //       ],
  //       flightNumKey: "TG-677-20241030",
  //       departureCityCode: "NRT",
  //       arrivalCityCode: "BKK",
  //       departureCityName: "TOKYO",
  //       arrivalCityName: "BANGKOK",
  //       departureAirportName: "TOKYO NARITA INTL",
  //       arrivalAirportName: "BANGKOK SUVARNABHUMI INTL",
  //       departureDate: "2024-10-30",
  //       departureTime: "08:30",
  //       arrivalDate: "2024-10-30",
  //       arrivalTime: "15:30",
  //       duration: "7h 0 min",
  //       departureTerminal: "1",
  //       flightStatus: "On Time",
  //       marketingCode: "TG",
  //       flightNum: "677",
  //       aircraftCode: "77W",
  //       aircraftType: "BOEING 777-300ER",
  //       id: "4D7F677020008CDC8C174097755A49AE8F7FA1C11730358990",
  //       passengers: [
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRX",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //         {
  //           firstName: "TEST",
  //           lastName: "TEST",
  //           title: "MR",
  //           journeyElementId: "5006540E0001D977",
  //           travelerId: "5104E40D0002C7D6",
  //           orderId: "5CVLRW",
  //           cabin: "Y",
  //           bookingClass: "Economy Class",
  //           infantOnSeat: false,
  //           infantAloneOnSeat: false,
  //         },
  //       ],
  //     },

  //   ],
  // };

  //This function is called when the user clicks on the Check-In button in the Manage/Check-in tab.
  const handleCheckIn = () => {
    const ticketNumber = ticketNo.toUpperCase();
    const username = lastName.replace(/\s/g, "");
    const dapiEnabled = process.env.REACT_APP_DAPI_FLOW || "";
    if (dapiEnabled === "true") {
      let requestData = {
        pnr: ticketNumber,
        lastName: username,
      };
      dispatch(retrieveJourneyRequest(requestData));
    } else {
      const checkinUrl =
        process.env.REACT_APP_TG_AMADEUS_URL + config.CHECKIN_API;
      const checkinQueryParams = "pnr=" + ticketNumber + "&lname=" + username;
      const amadeusRedirectionUrl = checkinUrl + "?" + checkinQueryParams;
      handleAmadeusRedirection(amadeusRedirectionUrl);
    }
  };

  useEffect(() => {
    if (retrieveJourneyResponse?.checkInSuccess) {
      // need to uncomment below line after rop user scenario 
      // setShowflight(true); 
    const eligibleFlightDetails =
    retrieveJourneyResponse?.checkinDetails?.filter(
      (item: any) =>
        item?.acceptanceEligibility?.status?.toLowerCase() === "eligible"
    );
  console.log("Eligible Flight Details :", eligibleFlightDetails);
  setFlightDetailsList(eligibleFlightDetails);
      navigate(`/${i18n.language}/checkin/select-passengers`);
    }
  }, [navigate, retrieveJourneyResponse?.checkInSuccess,(process.env.REACT_APP_DAPI_FLOW === 'true')]);

  useEffect(() => {
    if (
      retrieveJourneyResponse?.error &&
      retrieveJourneyResponse?.error?.length > 0
    ) {
      const errorCode = retrieveJourneyResponse?.error[0]?.code;
      const errorTitle = retrieveJourneyResponse?.error[0]?.title;

      if (errorCode === "194" || errorCode === "1012" || errorCode === "101") {
        setIsBookingNoError(false);
        setIsTicketNoError(false);
        setCheckinError(errorTitle);
      } else {
        setIsBookingNoError(true);
        setIsTicketNoError(true);
        setCheckinError("");
      }
    } else {
      setIsBookingNoError(false);
      setIsTicketNoError(false);
      setCheckinError("");
    }
  }, [retrieveJourneyResponse,(process.env.REACT_APP_DAPI_FLOW === 'true')]);

  const handleCloseFlightDetails = () => {
    setShowflight(false);
  };

  return isDesktopView ? (
    <>
      <div className={styles.flexContainer}>
        <div>
          <TGTextField
            label={t("label_check_in_reference")}
            placeholderText={t("label_check_in_reference")}
            type="text"
            variant={true}
            customInputClassName={styles["input-promotion-code"]}
            onTextChange={(e, label) => handleBookingNum(e, label)}
            ellipsis={true}
            value={ticketNo || ""}
            maxlength={maxLength}
            isError={isBookingNoError || checkinError}
            errorLabel={
              checkinError
                ? checkinError
                : isBookingNoError
                ? t("label_manage_example_error")
                : ""
            }
          />
          {!checkinError && (
            <ul
              className={
                isBookingNoError ? styles.customTextColor : styles.errorMargin
              }
            >
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_booking_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_ticket_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_check_in_ROP_example")}
              </li>
            </ul>
          )}
        </div>
        <div>
          <TGTextField
            label={t("label_manage_last_name")}
            placeholderText={t("label_manage_last_name")}
            type="text"
            variant={true}
            customInputClassName={styles["input-promotion-code"]}
            onTextChange={(e, label) => handlelastName(e, label)}
            value={lastName || ""}
            maxlength={40}
            isError={isNameError}
            errorLabel={t("label_manage_last_name_error")}
          />
        </div>

        <Button
          className={styles.buttonContainer}
          onClick={handleBooking}
          disabled={!isTicketNoError || !isLastNameError}
          aria-label="Check-In button"
        >
          <span>{t("label_book_widget_check_in")}</span>
        </Button>
      </div>

      {isAuthenticated ? (
        ""
      ) : (
        <div>
          <span className={styles.customTextColorNote}>
            {t("label_manage_note")}
          </span>
          <ul className={styles.customTextColor}>
            <li>{t("label_manage_icheckin")}</li>
            <li>{t("label_manage_document")}</li>
          </ul>
        </div>
      )}
      {/* checkin flight popup */}
      {showFlight && (
        <FlightCheckinDetails
          handleClose={handleCloseFlightDetails}
          flightDetails={flightDetailsList}
        />
      )}
    </>
  ) : (
    <>
      <div className={styles.flexContainerMobile}>
        <div>
          <div>
            <TGTextField
              label={t("label_check_in_reference")}
              placeholderText={t("label_check_in_reference")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handleBookingNum(e, label)}
              ellipsis={true}
              value={ticketNo || ""}
              maxlength={maxLength}
              isError={isBookingNoError || checkinError}
              errorLabel={
                checkinError
                  ? checkinError
                  : isBookingNoError
                  ? t("label_manage_example_error")
                  : ""
              }
            />
          </div>
          <div>
            <ul
              className={
                isBookingNoError ? styles.customTextColor : styles.errorMargin
              }
            >
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_booking_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_manage_ticket_example")}
              </li>
              <li
                className={
                  isBookingNoError ? styles.errorText : styles.customTextColor
                }
              >
                {t("label_check_in_ROP_example")}
              </li>
            </ul>
          </div>
          <div>
            <TGTextField
              label={t("label_manage_last_name")}
              placeholderText={t("label_manage_last_name")}
              type="text"
              variant={true}
              customInputClassName={styles["input-promotion-code"]}
              onTextChange={(e, label) => handlelastName(e, label)}
              value={lastName || ""}
              maxlength={40}
              isError={isNameError}
              errorLabel={t("label_manage_last_name_error")}
            />
          </div>
          {isAuthenticated ? (
            ""
          ) : (
            <div className={styles.notesContainer}>
              <span className={styles.customTextColorNote}>
                {t("label_manage_note")}
              </span>
              <ul className={styles.customTextColor}>
                <li>{t("label_manage_icheckin")}</li>
                <li>{t("label_manage_document")}</li>
              </ul>
            </div>
          )}
        </div>

        <div
          className={
            isAuthenticated
              ? styles.buttonAuthenticated
              : styles.buttonWidgetAllignmentMobile
          }
        >
          <TGButton
            label={t("label_book_widget_check_in")}
            variant="primary"
            buttonSize="lg"
            customClass="bookingButtonCheckIn"
            onClick={handleBooking}
            disabled={!isTicketNoError || !isLastNameError}
          />
        </div>
      </div>
      {showFlight && (
        <FlightCheckinDetails
          handleClose={handleCloseFlightDetails}
          flightDetails={flightDetailsList}
        />
      )}
    </>
  );
};

const CheckIn: React.FC<BookingCheckProps> = (props: BookingCheckProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  //This is used to determine if the current view is desktop view or mobile view.
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  //This is used to get the entire booking widget redux state.
  const bookingWidgetData = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget
  );
  const trip: tripDetails = useSelector(
    (state: RootState) => state?.osciReducer?.trips.upcomingTrips
  );
  const isAuthenticated = isAuthenticate();
  const data = trip?.pnrDetails?.map((resp: { flightDetails: any }) => {
    return resp.flightDetails;
  });
  const isTrips = useState(true);
  const [isBooking, setIsBooking] = useState(false);
  const [isBookingVal, setIsBookingVal] = useState("");
  const [showSignIn, setShowSignIn] = useState(false);
  const MANAGE_BOOKING_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(tripDataRequest());
    }
  }, [MANAGE_BOOKING_API_URL, dispatch, isAuthenticated]);

  useEffect(() => {
    if (props.isWidget) {
      i18n.changeLanguage("en-in");
    }
  }, []);

  const toggleRetrieveBooking = () => {
    setIsBooking(!isBooking);
  };

  function NoupcomingTrips() {
    return (
      <div
        className={
          isDesktopView ? styles.imageContainer : styles.imageContainerMobile
        }
      >
        <img src="/assets/noUpcomingTrips.svg" alt="No Upcoming Trips" />
        <p className={styles.imgText}>{t("label_no_upcoming_trips")}</p>
      </div>
    );
  }

  const handleSignInPopupClose = (callback?: Function): void => {
    setShowSignIn(false);
  };

  const handleSignInPopupShow = (): void => {
    setShowSignIn(true);
  };

  return (
    <>
      {isDesktopView &&
        (isAuthenticated ? (
          <div className={styles.upcomingContainer}>
            {data && data.length > 0 ? (
              <>
                <p className={styles.upcomingHeading}>
                  {t("label_upcomingtrips_header")}
                </p>
                <FlightStatusUpcomingTrips isMiniProfile={false} page="CIN" />
                {isTrips &&
                  (!isBooking ? (
                    <div
                      className={styles.addRetrieveContainer}
                      onClick={toggleRetrieveBooking}
                    >
                      <img src={"./assets/Add.svg"} alt="Add Booking" />
                      <span className={styles.upcomingHeading}>
                        {t("label_check_in_booking_reference")}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.retrieveBookingContainer}>
                      <div className={styles.retrieveBookingHeader}>
                        <span className={styles.upcomingHeading}>
                          {t("label_check_in_booking_reference")}
                        </span>
                        <button
                          onClick={toggleRetrieveBooking}
                          className={styles.buttonRadius}
                          aria-label="close-button"
                        >
                          <TGIcon icon="promo-close" fillColor="" size="" />
                        </button>
                      </div>
                      <TextINputField />
                    </div>
                  ))}
              </>
            ) : (
              <>
                <NoupcomingTrips />
                {isTrips &&
                  (!isBooking ? (
                    <div
                      className={styles.addRetrieveContainer}
                      onClick={toggleRetrieveBooking}
                    >
                      <img src={"./assets/Add.svg"} alt="Add Booking" />
                      <span className={styles.upcomingHeading}>
                        {t("label_check_in_booking_reference")}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.retrieveBookingContainer}>
                      <div className={styles.retrieveBookingHeader}>
                        <span className={styles.upcomingHeading}>
                          {t("label_check_in_booking_reference")}
                        </span>
                        <button
                          onClick={toggleRetrieveBooking}
                          className={styles.buttonRadius}
                          aria-label="close-button"
                        >
                          <TGIcon icon="promo-close" fillColor="" size="" />
                        </button>
                      </div>
                      <TextINputField />
                    </div>
                  ))}
              </>
            )}
          </div>
        ) : (
          <TextINputField />
        ))}
      {!isDesktopView &&
        (isAuthenticated ? (
          <div className={styles.upcomingContainer}>
            {data && data.length > 0 ? (
              <>
                <p className={styles.upcomingHeading}>
                  {t("label_upcomingtrips_header")}
                </p>
                <FlightStatusUpcomingTrips isMiniProfile={false} page="CIN" />
                {isTrips &&
                  (!isBooking ? (
                    <div
                      className={styles.addRetrieveContainer}
                      onClick={toggleRetrieveBooking}
                    >
                      <img src={"./assets/Add.svg"} alt="Add Booking" />
                      <span className={styles.upcomingHeading}>
                        {t("label_check_in_booking_reference")}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.retrieveBookingContainer}>
                      <div className={styles.retrieveBookingHeader}>
                        <span className={styles.upcomingHeading}>
                          {t("label_check_in_booking_reference")}
                        </span>
                        <button
                          onClick={toggleRetrieveBooking}
                          className={styles.buttonRadius}
                          aria-label="close-button"
                        >
                          <TGIcon icon="promo-close" fillColor="" size="" />
                        </button>
                      </div>
                      <TextINputField />
                    </div>
                  ))}
              </>
            ) : (
              <>
                <NoupcomingTrips />
                {isTrips &&
                  (!isBooking ? (
                    <div
                      className={styles.addRetrieveContainer}
                      onClick={toggleRetrieveBooking}
                    >
                      <img src={"./assets/Add.svg"} alt="Add Booking" />
                      <span className={styles.upcomingHeading}>
                        {t("label_check_in_booking_reference")}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.retrieveBookingContainer}>
                      <div className={styles.retrieveBookingHeader}>
                        <span className={styles.upcomingHeading}>
                          {t("label_check_in_booking_reference")}
                        </span>
                        <button
                          onClick={toggleRetrieveBooking}
                          className={styles.buttonRadius}
                          aria-label="close-button"
                        >
                          <TGIcon icon="promo-close" fillColor="" size="" />
                        </button>
                      </div>
                      <TextINputField />
                    </div>
                  ))}
              </>
            )}
          </div>
        ) : (
          <TextINputField />
        ))}
      {props.isWidget && !isAuthenticated && (
        <div className={styles["check-in-sign-in-section"]}>
          <div>
            <div>
              <h4>{t("label_signin_header")}</h4>
              <span>{t("label_signin_to_show")}</span>
            </div>
            <div>
              <div>
                <TGButton
                  variant={"primary"}
                  label={t("label_signin_header")}
                  onClick={handleSignInPopupShow}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showSignIn && (
        <LoginWidget
          onHidePanel={handleSignInPopupClose}
          loginName={() => {}}
          handlePanelShow={handleSignInPopupShow}
          isTabbed={true}
          handleSuccess={() => {}}
        />
      )}
    </>
  );
};

export default CheckIn;
